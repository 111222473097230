<template>
    <div>
      <b-card>
        <div class="m-2">
          <div class="row d-flex flex-column align-items-start">
            <h2 class="ml-2 font-weight-bold">{{ policy.policygroup_name }}</h2>
            <div class="ml-2 d-inline-block text-truncate text-primary" style="max-width: 200px">
              <p>{{ policy.description }}</p>
            </div>
          </div>
        </div>
      </b-card>
  
      <b-card>
        <div class="m-2">
          <div class="row d-flex flex-column align-items-start" >
            <h2 class="ml-1 font-weight-bold">Template Details</h2>
            <table role="table" class="table b-table mt-1">
              <thead role="rowgroup">
                <tr role="row">
                  <th role="columnheader" scope="col">SL No:</th>
                  <th role="columnheader" scope="col">Template Name</th>
                  <th role="columnheader" scope="col" class="text-center">Actions</th>
                </tr>
              </thead>
              <tbody role="rowgroup" v-if="paginatedTemplates.length > 0">
                <tr v-for="(pol, index) in paginatedTemplates" :key="pol.policy_id" role="row" class="cursor-pointer">
                  <td aria-colindex="1" role="cell" style="max-width: 20vw">{{ (currentPage - 1) * itemsPerPage + index + 1 }}</td>
                  <td aria-colindex="1" role="cell" style="max-width: 20vw">
                    <div class="d-flex flex-column align-items-start justify-content-center w-100">
                      <p class="mb-0">{{ pol.policy_name }}</p>
                    </div>
                  </td>
                  <td aria-colindex="2" role="cell">
                    <div class="d-flex flex-column align-items-center justify-content-center">
                      <b-button
                        v-if="pol.status === 1 || pol.status === 2"
                        size="sm"
                        variant="primary"
                      >Processing</b-button>
                      <div v-if="pol.status === 3">
                        <b-button
                          v-if="loading.includes(pol.policy_id)"
                          size="sm"
                          variant="success"
                          disabled
                        ><b-spinner small variant="secondary"/>Downloading</b-button>
                        <b-button
                          v-else
                          @click.stop="handleDownload(pol.policy_id, pol.request_id, pol.policy_name)"
                          size="sm"
                          variant="success"
                        ><feather-icon icon="DownloadIcon" class="mr-50"/>Download</b-button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
              <template v-else>
                <empty-table-section title="Policies Not Found">
                  <template #content>
                    <p class="font-weight-bold text-center">It seems like there are no policies at the moment.</p>
                  </template>
                </empty-table-section>
              </template>
            </table>
            <!-- Pagination Controls -->
            <b-pagination
              v-model="currentPage"
              :total-rows="policy.template_policies.length"
              :per-page="itemsPerPage"
              class="mt-2 ml-auto"
            ></b-pagination>
          </div>
        </div>
      </b-card>
    </div>
  </template>
  
  <script>
  import { BBadge, BButton, BCard, BCol, BPagination } from 'bootstrap-vue';
  import EmptyTableSection from "@/components/EmptyTableSection.vue";
  
  export default {
    components: {
      BCard,
      BCol,
      BButton,
      BBadge,
      BPagination,
      EmptyTableSection
    },
    data() {
      return {
        loading: [],
        currentPage: 1,
        itemsPerPage: 10 // Adjust the number of items per page as needed
      };
    },
    computed: {
      policy() {
        return this.$store.state.policies.myPolicy;
      },
      paginatedTemplates() {
        const start = (this.currentPage - 1) * this.itemsPerPage;
        const end = start + this.itemsPerPage;
        return this.policy.template_policies.slice(start, end);
      }
    },
    methods: {
      async handleDownload(polId, reqId, name) {
        const options = {
          method: "GET",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/toolkit/download-policy/`,
          params: { request_id: reqId, policy_id: polId },
          responseType: 'blob'
        };
  
        try {
          this.loading.push(polId);
          const res = await this.$http(options);
          // Create a Blob from the response data
          const blob = new Blob([res.data], { type: 'application/zip' });
          // Create a link element
          const link = document.createElement('a');
          // Create a URL for the Blob
          const url = window.URL.createObjectURL(blob);
          // Set the link's href to the Blob URL
          link.href = url;
          // Set the download attribute with a filename
          link.download = `download_${name}.docx`; // Customize the file name as needed
          // Append the link to the document body
          document.body.appendChild(link);
          // Trigger a click event on the link
          link.click();
          // Clean up and remove the link
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        } catch (error) {
          console.error('Error downloading the file:', error);
          // Handle the error as needed
        } finally {
          const index = this.loading.indexOf(polId);
          if (index !== -1) {
            this.loading.splice(index, 1);
          }
        }
      }
    },
    mounted() {
      console.log('policy', this.policy);
    }
  };
  </script>
  
  <style scoped>
  .template-card {
    border: 2px solid #007bff;
    border-radius: 8px;
    transition: transform 0.3s, box-shadow 0.3s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
  }
  
  .template-card:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .template-name {
    font-size: 1.25rem;
    font-weight: 400;
    text-align: center;
  }
  </style>
  